:root {
    --navy-blue: #2C3E50;
    --sky-blue: #4A90E2;
    --sky-blue-hover: #6c757d;
    --light-blue: #A3CFF0;
    --light-gray: #F5F7FA;
    --coral: #FF6B6B;
    --white: #FFFFFF;
    --dark-gray: #4A4A4A;
    --medium-gray: #5A5A5A;
  }


.footer {
  background-color:var(--navy-blue);
  color: white;
  text-align: left; /* Align text to the left */
  padding: 20px 20px; /* Add padding to the left and right */
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-content p{
  max-width: 1200px;
  margin: 0 auto; /* Center the footer content */
  padding: 0; /* Remove padding from the content */
  color:var(--white);
  font-size: 1rem;
}
