:root {
    --navy-blue: #2C3E50;
    --sky-blue: #4A90E2;
    --sky-blue-hover: #6c757d;
    --light-blue: #A3CFF0;
    --light-gray: #F5F7FA;
    --coral: #FF6B6B;
    --white: #FFFFFF;
    --dark-gray: #4A4A4A;
    --medium-gray: #5A5A5A;
  }


  

.landing-page {
    min-height: 100vh;
    background-color: var(--white);
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 20px;
}

.hero {
    display: grid;
    grid-template-columns: 3fr 2fr; /* Adjust the ratio between content and image */
    gap: 40px;
    align-items: center;
    margin-bottom: 30px;
    padding-top: 10px;
}

.hero-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px; /* Add left padding back */
}

.subtitle{
    margin-bottom: 10px;

}

.hero-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.view-demo-btn {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: var(--light-blue);
    color: var(--navy-blue);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.view-demo-btn:hover {
    background-color: #7FB8E0;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.pdf-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
}

.pdf-popup {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 800px; /* Set a max width for the PDF popup */
    width: 90%; /* Responsive width */
}

.image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
}

.image-popup {
    background: white;
    /* padding: 20px;
    border-radius: 8px; */
    position: relative;
    max-width: 500px; /* Set a max width for the image popup */
    width: 90%; /* Responsive width */
    align-items: center;
}


.close-btn {
    position: absolute;
    top: 2px;
    right: 0px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.hero-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    max-width: 300px; /* Set a smaller max width for the image popup */
    object-fit: contain; /* Changed from 'cover' to 'contain' */
}

h1{
    font-size: 3.2rem;
    margin-top:10px;
    margin-bottom: 10px;
    color: var(--navy-blue);
}

p {
    font-size: 1.2rem;
    color: var(--medium-gray);
    line-height: 1.6;
    margin-top: 10px;
}

.cta-button {
    padding: 12px 24px;
    font-size: 1.4rem;
    background-color: var(--sky-blue);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    align-self: center;
}

.cta-button:hover {
    background-color: var(--sky-blue-hover);
}

.features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    margin-top: 40px;
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.feature-image {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 0px;
}

.feature h3 {
    font-size: 1.5rem;
    color: var(--sky-blue);
    margin-bottom: 10px;
}

.feature p {
    font-size: 1rem;
    color: var(--medium-gray);
    line-height: 1.6;
    margin-top: 8px; /* Reduced from the default 16px */
    margin-bottom: 0; /* Remove bottom margin if not needed */
}

/* Ensure responsiveness */
@media (max-width: 768px) {
    .hero {
        grid-template-columns: 1fr; /* Stack vertically on smaller screens */
    }
    .hero-content {
        padding-left: 0; /* Remove left padding on mobile */
    }
    .main-heading {
        font-size: clamp(1.8rem, 4vw, 2.5rem); /* Slightly smaller on mobile */
    }
}
